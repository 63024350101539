import React, { useContext, useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import session from './session.js'
import ContactList from './ContactList/ContactList';
import AgentList from './AgentList/AgentList';
import { AgentContext } from './AgentContextProvider';
import logo from '../images/optum-logo-ora-rgb.png';
import 'amazon-connect-streams';
import { connectName, instanceId } from '../config.js'
import AgentTransferModal from "./AgentTransfer/AgentTransferModal";
import AgentNotes from "./AgentNotes/AgentNotes.jsx";
import useWebSocket from "react-use-websocket";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "99%",
    display: "flex"
  },
  logo: {
    margin: "auto"
  }
}));

export default function TopBar({
  onLayoutSave,
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
  idToken,
  taskClient,
  transferClient
}) {
  const classes = useStyles();
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const agentId = state.id;
  const connect = window.connect;
  const channels = state.channels;
  const [unreadEvents,setUnreadEvents] = useState(false);
  const [newAgentStatus, setNewAgentStatus] = useState(false);
  const [newQueues, setNewQueues] = useState(false);
  const [agentRows, setAgentRows] = useState([]);
  const [queueRows, setQueueRows] = useState([]);

  async function logout() {
    session.agent.setState(session.agent.getAgentStates().find((state) => state.name === 'Offline'), {
      success: function () {
        state.setIsLoggedIn(false, '', [], [], [], [], '')
        fetch("https://" + connectName + ".my.connect.aws/connect/logout", { credentials: 'include', mode: 'no-cors' })
          .then(() => {
            const eventBus = connect.core.getEventBus();
            eventBus.trigger(connect.EventType.TERMINATE);
          }
          );
        connect.core.terminate()
      },
      failure: function (err) { console.log(err) }
    })
}

  const reconnectEvent = ()=>{
    setUnreadEvents(true)
    setNewAgentStatus(true)
    setNewQueues(true)
 }

 let Callbacks = {
   "taskListUpdate": (data) => (setUnreadEvents(true)),
   'agentListRefresh': (data) => (setNewAgentStatus(true)),
   'queueListRefresh': (data) => (setNewQueues(true)),
   "close": (data) => data,
   "error": (data) => data,
   "open": (data) => reconnectEvent,
   "ping": (data) => data,
 };

 const OnEventMessage = (data)  => {
  console.info(data)
   try {
       let event = JSON.parse(data.data);
       let eventType = event.event_type;
       return Callbacks[eventType](event);
   } catch {
       return data;
   }
 };

const queryParams = {"agentId":agentId, "instanceId":instanceId}
 // Configure websocket callbacks
 useWebSocket(process.env.REACT_APP_WSS_API_BASE, {
   onMessage: (e) => OnEventMessage(e),
   onOpen: (e) => {console.info('Connected')},
   onClose: (e) => { console.info('Disconnected') },
   onError: (e) => { console.info(e) },
   shouldReconnect: (closeEvent) => true,
   queryParams: (queryParams)
 });

 useEffect(() => {
  updateAgentRows()
  updateQueueRows()
}, [])

useEffect(() => {
  console.info('It ')
  if (newAgentStatus === true) {
      updateAgentRows()
      setNewAgentStatus(false)
  }
}, [newAgentStatus])

useEffect(() => {
  if (newQueues === true) {
      updateQueueRows()
      setNewQueues(false)
  }
}, [newQueues])

const updateAgentRows = () => {
  transferClient.getAgents(idToken)
      .then((response) => response.json())
      .then((res) => setAgentRows(res))
}

const updateQueueRows = () => {
  transferClient.getQueues(idToken)
      .then((response) => response.json())
      .then((res) => setQueueRows(res))
}

  return (
    <Card className={classes.root}>
      <div className={classes.logo}><img src={logo} width={140} height={40} alt='logo' /></div>
      {isLoggedIn ?
        <>
          <AgentNotes
          taskClient={taskClient} transferClient={transferClient} setUnreadEvents={setUnreadEvents} unreadEvents={unreadEvents} agentRows={agentRows} />
          {channels.includes('VOICE') ?
                    <AgentTransferModal
                    transferClient={transferClient}
                    idToken={idToken}
                    setNewAgentStatus={setNewAgentStatus}
                    setNewQueues={setNewQueues}
                    newAgentStatus={newAgentStatus}
                    newQueues={newQueues}
                    agentRows={agentRows}
                    queueRows={queueRows}
                    />: null}
          <AgentList agentRows={agentRows} />          
          <ContactList />
          <IconButton aria-label="save" onClick={logout}>
          <LogoutIcon />
          </IconButton>

        </>
        : null}

    </Card>
  );
}